nav {
	position: fixed;
	width:calc(100% - 10px);
	color:orangered;
	bottom:0;
	left:0;
	z-index:100;
	display:flex;
	text-shadow:0px 0px 20px rgba(0, 0, 0, 0.15);
	text-transform: uppercase;
	padding:5px;
	transition: transform 0s;

	nav__element {
		flex:1;
		text-align: center;
		
		&:hover {
			cursor: pointer;
		}

		&:nth-child(3) {
			padding-right:12px !important
		}

		&.raumnav {
			flex:1;
			text-align: center;
			padding:5px;

			&:hover {
				cursor: pointer;
			}
		}
		
	}
	
}


@media only screen and (max-width: $breakpoint-mobile) and (orientation: landscape) {
	

	nav {

		display:none;

	// 	transform:rotate(90deg) translateX(-100%);
	// 	width:calc(100vh);
	// 	transform-origin: bottom left;
	// 	font-size:6.5vh;
	// 	padding-top:5px;

	// 	nav__element {
	// 		&:first-child {
	// 			padding-left:12px !important
	// 		}
	// 	}
		
	}

}