intro {
	// width:100vw;
	// height:100vh;
	width:100vw;
	height:100vh;
	position: fixed;
	top:0;
	left:0;
	z-index:150;
	display:flex;
	flex-direction: column;
	text-align: center;
	color: yellow;
	justify-content: center;
	pointer-events: none;
	text-shadow:0px 0px 20px rgba(0, 0, 0, 0.4);
	// background-color: red;

	.intro__element {
		transform-origin: 50% 50%;
		transform: rotate(-90deg);
		transition: transform 0s;
		padding-bottom:20px;
		display:none;
		flex:1;
		line-height: 0.85;
		position: absolute;		
		align-self: center;
		// background-color:green;
		width:80vh;
		padding-top:10px;
	}

	titel {
		flex-direction: column;
		align-self: center;
		justify-content: center;
		font-size: 22vw;
	}
	
	subtitle {
		font-size: 22vw;
		padding-right:2vw;
	}
	
	kuenstlerin {
		color:orangered;
		font-size: 22vw;
	}
	
	ort {
		color:blue;
		font-size: 16vw;
	}
	
	laufzeit {
		color:blue;
		font-size: 16vw;
		font-family: Arial, Helvetica, sans-serif;
	}
}



@media only screen and (max-width: $breakpoint-mobile) and (orientation: landscape) {
	intro {
		height:100vh;

		.intro__element {
			width:80vw;
			top:50%;
			// display: block;
			transform: rotate(0) translateY(-50%);
		}

		titel {
			font-size: 15vw;
		}
		
		subtitle {
			font-size: 14vw;
		}
		
		kuenstlerin {
			font-size: 15vw;
		}
		
		ort {
			font-size: 8vw;
		}
		
		laufzeit {
			font-size: 8vw;
		}

	}
}



@media only screen and (min-width: $breakpoint-mobile) {

	intro {

		text-shadow:0px 0px 50px rgba(0, 0, 0, 0.25);
		height:100vh;
		width:100vw;
		transform: rotate(0);
		transform-origin: unset;
		
		.intro__element {
			// font-size: 17vw;
			height:unset;
			width:100vw;
			line-height:0.8;
			transform: rotate(0);
			padding-bottom:0;
		}
		
		titel, kuenstlerin {
			font-size: 15vw;
		}
		
		subtitle {
			font-size: 14vw;
		}
		
		ort {
			font-size: 7vw;
		}
		
		laufzeit {
			font-size: 6vw;
		}
	}


}