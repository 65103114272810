#bild {
	background:rgba(0,0,255,0.6 );
	// background:rgba(100,100,100,0.9 );
	// background:rgba(150,150,150,0.8 );
	// background:darkgrey;
	// backdrop-filter: blur(7px);
	position: fixed;
	top:0;
	left:0;
	width:100vw;
	height:100vh;
	z-index:50;

	bilder-nav {
		position: fixed;
		display:flex;
		flex-direction: row;
		z-index:100;
		top:0;
		height:100vh;
		width:100vw;

		prev, next {
			flex:1;
		}
	}

	bilder {
		position: fixed;
		top:0;
		left:0;
		width:100vw;
		height:100vh;
		z-index:80;
		display:flex;
		align-items: center;
		pointer-events: none;
		justify-content: center;
	}
	
	bild-element {
		display: flex;
		align-items: center;
		flex-direction: column;
		text-align: center;
		position: absolute;
		width:100%;
		flex:1;
		top:50%;
		transform: translateY(-50%);
		color:orangered;
		font-size: $fs-courier-mobile;
		font-family: 'Courier New', Courier, monospace;

		&.current-slide {
			display:flex;
		}
		
		&.slide {
			display:none;
		}

		counter {
			padding-bottom:2px;
		}
	
		img {
			width:95%;
			max-height: 70vh;
			object-fit: contain;
		}

		span {
			width:90%;
			padding-top:3px;
		}
		
	}
}


@media only screen and (min-width: $breakpoint-mobile) {

	#bild {
		
		bilder {
			width:50vw;
			left:50vw;
			transform: translateX(-50%);
			transition: transform 0.5s ease-in-out, left 0.5s ease-in-out;

			bild-element {
				font-size: 0.9vw;
				
				img {
					max-height: 60vh;
				}
			}
		
		}
	}

}