#funk {
	position: fixed;
	top:0;
	left:0;
	width:100vw;
	height:100vh;
	display:flex;
	flex-direction: column;
	overflow-y:scroll;
	overflow-x:hidden;
	text-shadow:0px 0px 10px rgba(0, 0, 0, 0.5);
	// background-color: black;
	color:orangered;
	font-family: 'Courier New', Courier, monospace;

	impressum-logo {

		svg {
			padding-top:30px;
			padding-bottom:80px;
			width:300px;
		}
		
		.st0 {
			fill:yellow
		}
	}

	impressum {
		width:90vw;
		font-size:$fs-courier-mobile;
		line-height:1;
		columns:1;
		font-family: 'Courier New', Courier, monospace;
		padding-left:10px;
		padding-bottom:100px;
		color:yellow;
		padding-top:15vh;
		text-align: center;
		align-self:center;
		font-size: 4vw;

		impressum-col {
			padding-bottom:100px;
			padding-right:12px;
			display:block;

			.ignota {
				font-family: "IgnotaArial";
			}

			p {
				margin-bottom:12px;
			
				&.credittitle {
					text-transform: uppercase;
				}
			}
		}
	}
	


}




@media only screen and (min-width: $breakpoint-mobile) {

	#funk {

		text-shadow:0px 0px 20px rgba(0, 0, 0, 0.35);

		impressum {
			width:60vw;
			font-size:1.5vw;
			padding-top:20vh;
			margin-bottom:10vh;
			align-self: center;
			// display: flex;
			// flex-direction: column;
			
			impressum-col {
				padding-bottom:0;
				flex:1;
			}
		}
	}

}