audio-controls {
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 12px);
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0s;
  padding-left: 5px;
  padding-right: 5px;
}
audio-controls spur {
  position: relative;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  height: 17vw;
}
audio-controls spur:hover {
  cursor: pointer;
}
audio-controls spur .schalter-hidden {
  visibility: hidden;
}
audio-controls spur .schalter-visible {
  visibility: visible;
}
audio-controls spur .play {
  padding-top: 0px;
  padding-top: 5px;
  font-size: 10vw;
}
audio-controls spur .pause {
  padding-top: 5px;
  font-family: Arial, Helvetica, sans-serif;
}
audio-controls spur schalter-hover {
  font-family: Arial, Helvetica, sans-serif;
}
audio-controls spur schalter-hover,
audio-controls spur alt-text {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  line-height: 1.52;
}
audio-controls spur schalter-nohover {
  opacity: 1;
}
audio-controls spur .alt-second {
  display: none;
}
@media only screen and (max-width: 1000px) and (orientation: landscape) {
  audio-controls {
    display: none;
  }
  audio-controls .play {
    font-size: 6vw !important;
  }
}
@media only screen and (min-width: 1000px) {
  audio-controls spur {
    height: unset;
    padding: 5px;
  }
  audio-controls spur .play {
    padding-top: 20px;
    font-size: 6vw;
  }
  audio-controls spur .pause {
    padding-top: 10px;
  }
  audio-controls spur schalter-hover,
audio-controls spur alt-text {
    line-height: 1.34;
    padding-top: 10px;
  }
}
nav {
  position: fixed;
  width: calc(100% - 10px);
  color: orangered;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
  padding: 5px;
  transition: transform 0s;
}
nav nav__element {
  flex: 1;
  text-align: center;
}
nav nav__element:hover {
  cursor: pointer;
}
nav nav__element:nth-child(3) {
  padding-right: 12px !important;
}
nav nav__element.raumnav {
  flex: 1;
  text-align: center;
  padding: 5px;
}
nav nav__element.raumnav:hover {
  cursor: pointer;
}
@media only screen and (max-width: 1000px) and (orientation: landscape) {
  nav {
    display: none;
  }
}
intro {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: yellow;
  justify-content: center;
  pointer-events: none;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}
intro .intro__element {
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
  transition: transform 0s;
  padding-bottom: 20px;
  display: none;
  flex: 1;
  line-height: 0.85;
  position: absolute;
  align-self: center;
  width: 80vh;
  padding-top: 10px;
}
intro titel {
  flex-direction: column;
  align-self: center;
  justify-content: center;
  font-size: 22vw;
}
intro subtitle {
  font-size: 22vw;
  padding-right: 2vw;
}
intro kuenstlerin {
  color: orangered;
  font-size: 22vw;
}
intro ort {
  color: blue;
  font-size: 16vw;
}
intro laufzeit {
  color: blue;
  font-size: 16vw;
  font-family: Arial, Helvetica, sans-serif;
}
@media only screen and (max-width: 1000px) and (orientation: landscape) {
  intro {
    height: 100vh;
  }
  intro .intro__element {
    width: 80vw;
    top: 50%;
    transform: rotate(0) translateY(-50%);
  }
  intro titel {
    font-size: 15vw;
  }
  intro subtitle {
    font-size: 14vw;
  }
  intro kuenstlerin {
    font-size: 15vw;
  }
  intro ort {
    font-size: 8vw;
  }
  intro laufzeit {
    font-size: 8vw;
  }
}
@media only screen and (min-width: 1000px) {
  intro {
    text-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
    height: 100vh;
    width: 100vw;
    transform: rotate(0);
    transform-origin: unset;
  }
  intro .intro__element {
    height: unset;
    width: 100vw;
    line-height: 0.8;
    transform: rotate(0);
    padding-bottom: 0;
  }
  intro titel, intro kuenstlerin {
    font-size: 15vw;
  }
  intro subtitle {
    font-size: 14vw;
  }
  intro ort {
    font-size: 7vw;
  }
  intro laufzeit {
    font-size: 6vw;
  }
}
#bild {
  background: rgba(0, 0, 255, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
}
#bild bilder-nav {
  position: fixed;
  display: flex;
  flex-direction: row;
  z-index: 100;
  top: 0;
  height: 100vh;
  width: 100vw;
}
#bild bilder-nav prev, #bild bilder-nav next {
  flex: 1;
}
#bild bilder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 80;
  display: flex;
  align-items: center;
  pointer-events: none;
  justify-content: center;
}
#bild bild-element {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  width: 100%;
  flex: 1;
  top: 50%;
  transform: translateY(-50%);
  color: orangered;
  font-size: 3vw;
  font-family: "Courier New", Courier, monospace;
}
#bild bild-element.current-slide {
  display: flex;
}
#bild bild-element.slide {
  display: none;
}
#bild bild-element counter {
  padding-bottom: 2px;
}
#bild bild-element img {
  width: 95%;
  max-height: 70vh;
  object-fit: contain;
}
#bild bild-element span {
  width: 90%;
  padding-top: 3px;
}
@media only screen and (min-width: 1000px) {
  #bild bilder {
    width: 50vw;
    left: 50vw;
    transform: translateX(-50%);
    transition: transform 0.5s ease-in-out, left 0.5s ease-in-out;
  }
  #bild bilder bild-element {
    font-size: 0.9vw;
  }
  #bild bilder bild-element img {
    max-height: 60vh;
  }
}
#flur {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  left: 0;
  top: 0;
  margin: 0;
  height: 102vh;
  width: 102vh;
  transform-origin: top top;
  transform: rotate(-90deg);
  transition: transform 0s;
  background-color: grey;
}
video {
  width: 102%;
}
.wistia_responsive_padding {
  pointer-events: none;
}
.w-ui-container {
  opacity: 0 !important;
  pointer-events: none;
}
@media only screen and (max-width: 1000px) and (orientation: landscape) {
  #flur {
    height: 100vh;
    width: 100vw;
    transform: rotate(0);
    object-fit: cover;
  }
  #flur .flur__video {
    object-fit: cover;
    height: 100vh;
  }
}
@media only screen and (min-width: 1000px) {
  #flur {
    height: 100vh;
    width: 100vw;
    transform: rotate(0);
    overflow-y: scroll;
  }
  #flur .flur__video {
    height: 100vh;
  }
  #flur .flur__video.video__random {
    display: block;
  }
}
#funk {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  color: orangered;
  font-family: "Courier New", Courier, monospace;
}
#funk impressum-logo svg {
  padding-top: 30px;
  padding-bottom: 80px;
  width: 300px;
}
#funk impressum-logo .st0 {
  fill: yellow;
}
#funk impressum {
  width: 90vw;
  font-size: 3vw;
  line-height: 1;
  columns: 1;
  font-family: "Courier New", Courier, monospace;
  padding-left: 10px;
  padding-bottom: 100px;
  color: yellow;
  padding-top: 15vh;
  text-align: center;
  align-self: center;
  font-size: 4vw;
}
#funk impressum impressum-col {
  padding-bottom: 100px;
  padding-right: 12px;
  display: block;
}
#funk impressum impressum-col .ignota {
  font-family: "IgnotaArial";
}
#funk impressum impressum-col p {
  margin-bottom: 12px;
}
#funk impressum impressum-col p.credittitle {
  text-transform: uppercase;
}
@media only screen and (min-width: 1000px) {
  #funk {
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
  }
  #funk impressum {
    width: 60vw;
    font-size: 1.5vw;
    padding-top: 20vh;
    margin-bottom: 10vh;
    align-self: center;
  }
  #funk impressum impressum-col {
    padding-bottom: 0;
    flex: 1;
  }
}
#wort {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  background: rgba(200, 200, 200, 0.7);
  color: #7a7a7a;
  font-size: 4vw;
  line-height: 1;
  display: flex;
  z-index: 50;
}
#wort text-wrapper {
  width: 50vw;
  height: 100vh;
  overflow-y: scroll;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  line-height: 0.93;
  word-break: break-word;
  padding-top: 20px;
  margin-bottom: 50px;
}
#wort text-wrapper.currently-active .reading-1 {
  color: blue !important;
}
#wort text-wrapper.currently-active .reading-2,
#wort text-wrapper.currently-active .reading-3,
#wort text-wrapper.currently-active .reading-4 {
  color: yellow;
}
#wort text-wrapper.currently-inactive .reading {
  color: #c1c1c1 !important;
}
@media only screen and (min-width: 1000px) {
  #wort {
    font-size: 2vw;
  }
  #wort text-wrapper {
    font-size: 1.8vw;
    width: 25vw;
  }
}
@font-face {
  font-family: "IgnotaArial";
  src: url("IgnotaArial3.0-Normal.93f813b9.woff2") format("woff2"), url("IgnotaArial3.0-Normal.012bf28b.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, img, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  list-style: none;
}
body, html {
  height: 100vh;
  overflow: hidden;
}
body {
  font-family: "IgnotaArial", Arial, Helvetica, sans-serif;
  font-size: 7vw;
}
p {
  margin-bottom: 15px;
}
.desktop {
  display: none;
}
.mobile {
  display: block;
}
.visible {
  display: block;
}
.hidden {
  display: none;
}
.nopointerevents {
  pointer-events: none;
}
.overflowhidden {
  overflow-y: hidden !important;
}
.onpageload {
  left: 0;
}
.blue {
  color: blue;
}
.yellow {
  color: yellow;
}
.beforesliding {
  transform: translateX(100%);
  z-index: 0;
  opacity: 0;
}
#flur.beforesliding {
  opacity: 1 !important;
}
.slidingin {
  transform: translateX(0);
  z-index: 10;
  opacity: 1;
}
.slidingout {
  transform: translateX(-100%);
  z-index: 0;
  opacity: 1;
}
.raum {
  transition: transform 0.3s ease-in-out, opacity 0s;
  transition-delay: transform 0s, opacity 1s;
}
a, a:active, a:visited, a:hover {
  color: blue;
}
@media only screen and (min-width: 1000px) {
  body {
    font-size: 6vw;
  }

  p {
    margin-bottom: 25px;
  }

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}
/*# sourceMappingURL=index.70ce7526.css.map */
