#flur {
	display:flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: hidden;
	left:0;
	top:0;
	margin:0;
	height:102vh;
	width:102vh;
	transform-origin: top top;
	transform: rotate(-90deg);
	transition: transform 0s;
	background-color:grey;

	.flur__video {

		&.video__random {
			// display:none;
		}
	}
}

video {
	width:102%;
}

.wistia_responsive_padding {
	pointer-events: none;
}

.w-ui-container {
	opacity:0 !important;
	pointer-events: none;
}


@media only screen and (max-width: $breakpoint-mobile) and (orientation: landscape) {
	#flur {

		height:100vh;
		width:100vw;
		transform: rotate(0);
		// object-fit: contain;
		object-fit: cover;
		
		.flur__video {
			// object-fit: contain;
			object-fit: cover;
			height:100vh;
		}

	}
}

@media only screen and (min-width: $breakpoint-mobile) {

	#flur {

		height:100vh;
		width:100vw;
		transform: rotate(0);
		overflow-y: scroll;

		.flur__video {
			height:100vh;

			&.video__random {
				display:block;
			}
		}

	}

}