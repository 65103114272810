audio-controls {
	position: fixed;
	top:0;
	// bottom:0;
	left:0;
	width:calc(100% - 12px);
	z-index:100;
	display:flex;
	flex-direction: row;
	justify-content: flex-end;
	text-shadow:0px 0px 20px rgba(0, 0, 0, 0.15);
	transition: transform 0s;
	padding-left:5px;
	padding-right:5px;

	spur {
		position: relative;
		border-radius: 100%;
		display:flex;
		align-items: center;
		justify-content: center;
		flex:1; 
		flex-grow:0;
		flex-shrink:0;
		flex-basis: 25%;
		height:17vw;
		// height:60px;
		// padding:5px;

		&:hover {
			cursor: pointer;
		}

		.schalter-hidden {
			visibility:hidden;
		}

		.schalter-visible {
			visibility:visible;
		}

		.play {
			padding-top:0px;
			padding-top:5px;
			font-size:10vw;
		}
		
		.pause {
			padding-top:5px;
			font-family: Arial, Helvetica, sans-serif;
		}

		schalter-hover {
			font-family: Arial, Helvetica, sans-serif;
		}
		
		schalter-hover,
		alt-text {
			position:absolute;
			top:0;
			left:50%;
			// padding-top:10px;
			transform: translateX(-50%);
			display: flex;
			line-height: 1.52;
		}
		
		schalter-nohover {
			opacity:1;
		}

		.alt-second {
			display:none;
		}
		
	}
}


@media only screen and (max-width: $breakpoint-mobile) and (orientation: landscape) {

	audio-controls  {

		display:none;
		
		.play {
			font-size:6vw !important
		}
	}

}


@media only screen and (min-width: $breakpoint-mobile) {

	audio-controls {
	
		spur {

			height:unset;

			padding:5px;
	
			.play {
				padding-top:20px;
				font-size:6vw;
			}
	
			.pause {
				padding-top:10px;
			}

			schalter-hover,
			alt-text {
				line-height: 1.34;
				padding-top:10px;
			}
			
		}
	}

}