@import 'base__variables';
@import 'comp__audio-controls';
@import 'comp__nav';
@import 'comp__intro';
@import 'raum__bild';
@import 'raum__flur';
@import 'raum__funk';
@import 'raum__wort';

@font-face {
    font-family: "IgnotaArial";
    src: url('fonts/IgnotaArial3.0-Normal.woff2') format('woff2'),
    url('fonts/IgnotaArial3.0-Normal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html, body, div, span, h1, h2, h3, h4, h5, h6, p, a, img, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	text-decoration: none;
	list-style: none;
}

body, html {
	height:100vh;
	overflow: hidden;
}

body {
	font-family: "IgnotaArial", Arial, Helvetica, sans-serif;
	font-size: 7vw;
}

p {
	margin-bottom:15px;

}

.desktop {
	display:none;
}

.mobile {
	display:block;
}

.visible {
	display:block;
}

.hidden {
	display:none;
}

.nopointerevents {
	pointer-events: none;
}

.overflowhidden {
	overflow-y:hidden !important;
}

.onpageload {
	left:0;
}

.blue {
	color:blue
}

.yellow {
	color:yellow
}

.beforesliding {
	transform: translateX(100%);
	z-index:0;
	opacity: 0;
}

#flur.beforesliding {
	opacity: 1 !important
}

.slidingin {
	transform: translateX(0);
	z-index:10;
	opacity: 1;
}

.slidingout {
	transform: translateX(-100%);
	z-index:0;
	opacity: 1;
	// opacity: 0;
}

.raum {
	transition: transform 0.3s ease-in-out, opacity 0s;
	transition-delay: transform 0s, opacity 1s;
	// opacity:0.5;
}

a, a:active, a:visited, a:hover {
	color:blue;
}


@media only screen and (min-width: $breakpoint-mobile) {

	body {
		font-size: 6vw;
	}

	p {
		margin-bottom:25px;
	}

	.desktop {
		display:block;
	}
	
	.mobile {
		display:none;
	}

}