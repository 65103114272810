#wort {
	position: fixed;
	height:100vh;
	width:100vw;
	top:0;
	left:0;
	// background:#e1e1e1;
	backdrop-filter: blur(10px);
	background:rgba(200,200,200,0.7 );
	color:#7a7a7a;
	// color:#c1c1c1;
	font-size:4vw;
	line-height: 1;
	display: flex;
	z-index:50;

	text-wrapper {
		width:50vw;
		height:100vh;
		overflow-y:scroll;
		padding-left:5px;
		padding-right:5px;
		text-align: center;
		line-height:0.93;
		// font-size:2.2vw;
		word-break: break-word;
		padding-top:20px;
		margin-bottom:50px;


		&.currently-active {


			.reading-1 {
				color: blue !important;
			
			}
		
			.reading-2,
			.reading-3,
			.reading-4 {
				color: yellow;
			}
		}

		&.currently-inactive {

			.reading {
				color: #c1c1c1 !important;
			
			}
		}


		// .start {
		// 	border: 1px solid black
		// }
	
	}

	// #text__geraeusche .reading:last-child {
	// 	color: blue !important;
	
	// }

	// #text__spokenword1 .reading,
	// #text__spokenword2 .reading,
	// #text__spokenword3 .reading {
	// 	color: yellow;
	// }
	
}

@media only screen and (min-width: $breakpoint-mobile) {

	#wort {
		font-size:2vw;

		text-wrapper {
			font-size:1.8vw;
			width:25vw;
		}
	}


}